import LayoutSettings from 'app/Models/Content/LayoutSettingsData';

export function createLayoutSettingReduxPayload(layoutSettings: LayoutSettings) {
    const enoughInfoToDisplay =
        layoutSettings?.feedbackErrorSendingMail != null &&
        layoutSettings?.feedbackFromEmail != null &&
        layoutSettings?.feedbackToEmail != null &&
        layoutSettings?.feedbackText != null &&
        layoutSettings?.feedbackNoHeading != null &&
        layoutSettings?.feedbackYesFeedback != null;

    const payload = {
        FeedbackToEmail: layoutSettings?.feedbackToEmail?.value,
        FeedbackFromEmail: layoutSettings?.feedbackFromEmail?.value,
        FeedbackText: layoutSettings?.feedbackText?.value,
        FeedbackNoHeading: layoutSettings?.feedbackNoHeading?.value,
        FeedbackYesFeedback: layoutSettings?.feedbackYesFeedback?.value,
        FeedbackNoFreeText: layoutSettings?.feedbackNoFreeText?.value,
        EmptyTextFieldValidationMessage: layoutSettings?.feedbackErrorEmptyTextField?.value,
        FeedbackErrorSendingMail: layoutSettings?.feedbackErrorSendingMail?.value,
        EnoughInfoToDisplay: enoughInfoToDisplay,
    };

    return payload;
}
